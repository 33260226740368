import React, { Component } from "react";
import { NavLink, HashRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Row, Col } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
class Footer extends Component {
  render() {
    const { t } = this.props;
    return (
      <div className="footer-akwaba">
        <p>{t("footer.poweredby")}</p>
        <div>

          <Container>
            <Row>
              <HashRouter>
                <Col md={{ span: 3, offset: 3 }}> <div className="akwaba-container-items"><div > <NavLink to="/contactmessage2"><span className="akwaba-tiles-navlinks">{t("footer.ask")}</span></NavLink></div></div></Col>
                <Col md={{ span: 3, offset: 0 }}><div className="akwaba-container-items"><div ><NavLink to="/disclaimer"><span className="akwaba-tiles-navlinks">{t("footer.disclaimer")}</span></NavLink></div></div></Col>
                {/* <Col md={{ span: 2, offset: 0 }}><div className="akwaba-container-items"><div className="akwaba-tiles-navlinks"><NavLink to="/contactmessage"><span>contact message2</span></NavLink></div></div></Col>    */}
              </HashRouter>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Footer);