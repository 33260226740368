import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { HashRouter, NavLink } from "react-router-dom";
import DakonProductsInfoTile from "./tiles/DakonProductsInfoTile";
import DakonTradeTile from "./tiles/DakonTradeTile";
import DakonProductionTile from "./tiles/DakonProductionTile";
import CarouselDakonProduction from "./animations/Carousel";

class DakonProductions extends Component {
  render() {
    const { t } = this.props;
    return (
      <div>
        <div className="slideshow_deploy">
        <Row>
          <Col md={{ span: 12, offset: 0 }}>
            <CarouselDakonProduction />
          </Col>
        </Row>
        </div>
        <div className="slideshow_deploy_web"></div>
        <div className="akwaba-content_deploy">  
          <Row>
            <HashRouter>  
            <Col md={{ span: 3, offset: 1 }}>
                <div className="akwaba-container-items">
                  <NavLink to="/dakonproductionsInfos">
                    <div className="akwaba-tiles-navlinks">
                    {t("pages.dakonproductions.aboutus")}              
                    </div>
                  </NavLink>
                  <DakonProductionTile/>
                </div>
              </Col>    

              <Col md={{ span: 3, offset: 0 }}>
                <div className="akwaba-container-items">
                  <NavLink to="/dakonproductinfos">
                    <div className="akwaba-tiles-navlinks">
                    {t("pages.dakonproductions.abouttheproduct")}
                    </div>
                  </NavLink>
                  <DakonProductsInfoTile/>
                </div>
              </Col>

              <Col md={{ span: 3, offset: 0 }}>
                {" "}
                <div className="akwaba-container-items">
                  <NavLink to="/dakonsolutions">
                    <div className="akwaba-tiles-navlinks">
                    {t("pages.dakonproductions.howtogettheproduct")}
                    </div>
                  </NavLink>
                  <DakonTradeTile/>
                </div>
              </Col>             
            </HashRouter>
          </Row>
        </div>
        <div></div>
      </div>
    );
  }
}

export default withTranslation()(DakonProductions);
