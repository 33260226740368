import React, { Component } from "react";
//import akwabagroup1 from "../../img/akwabagroup1.PNG";
import {NavLink, HashRouter } from "react-router-dom";
import Accordion from 'react-bootstrap/Accordion';
import Card from "react-bootstrap/Card";
import { withTranslation  } from "react-i18next";

class DakonProductionsInfos extends Component {
  render() {
    const { t } = this.props;
    return (
      <div className="content-justscript-akwaba">   
<p>Family business created in 2005. The typhoid disease is ravaging when the matriarch discovered <b/>
a healing potion. It is widely recognised and even recommended by doctors. </p>
     </div> 
    );
  }
}

export default withTranslation ()(DakonProductionsInfos);
