import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Route, HashRouter } from "react-router-dom";
import Home from "./clientapp/pages/Home";
import Services from "./clientapp/pages/Services";
import Contact from "./clientapp/pages/Contact";
import ContactMessage2 from "./clientapp/pages/messages/ContactMessage2";
import Menue from "./clientapp/pages/Menue";
import References from "./clientapp/pages/References";
import Booking2 from "./clientapp/pages/bookings/Booking2";
import Register from "./clientapp/registerlogin/Register";
import RegisterSimple from "./clientapp/registerlogin/RegisterSimple";
import Loginscreen from "./clientapp/registerlogin/LoginScreen";
import Login from "./clientapp/registerlogin/Login";
import RetrieveMyMessages from "./clientapp/pages/messages/RetrieveMyMessages";
import MessagesDisplay from "./clientapp/pages/messages/MessagesDisplay";
import MyAdmin from "./clientapp/pages/admin/MyAdmin";
import AdminPage from "./clientapp/admin/AdminPage";
import BookingAdmin from "./clientapp/admin/BookingAdminPage";
import MessageAdmin from "./clientapp/admin/MessageAdminPage";
import DakonProductions from "./clientapp/pages/dakonproduction/DakonProductions";
import DakonProductInfos from "./clientapp/pages/dakonproduction/DakonProductInfos";
import DakonProductionsInfos from "./clientapp/pages/dakonproduction/DakonProductionsInfos";
import DakonSolutions from "./clientapp/pages/dakonproduction/DakonSolutions";
import Disclaimer from "./clientapp/legals/disclaimer";
import BusinessTrainings from "./clientapp/trainings/BusinessTrainings";
import SoftwareDevTrainings from "./clientapp/trainings/SoftwareDevTrainings";
import Trainings from "./clientapp/admin/Trainings";
import Logout from "./clientapp/registerlogin/Logout";

class Main extends Component {
  render() {
    return (
      <div>
        <HashRouter>
          <Route exact path="/" component={Home} />
          <Route path="/home" component={Home} />
          <Route path="/services" component={Services} />
          <Route path="/menue" component={Menue} />
          <Route path="/reference" component={References} />
          <Route path="/booking" component={Booking2} />
          <Route path="/register" component={Register} />
          <Route path="/registersimple" component={RegisterSimple} />
          <Route path="/myadmin" component={MyAdmin} />
          <Route path="/bookingadmin" component={BookingAdmin} />
          <Route path="/messageadmin" component={MessageAdmin} />
          <Route path="/adminpage" component={AdminPage} />
          <Route path="/loginscreen" component={Loginscreen} />
          <Route path="/login" component={Login} />
          <Route path="/contact" component={Contact} />
          <Route path="/contactmessage2" component={ContactMessage2} />
          <Route path="/retrievemymessages" component={RetrieveMyMessages} />
          <Route path="/displaymymessages" component={MessagesDisplay} />
          <Route path="/dakonsolutions" component={DakonSolutions} />
          <Route path="/dakonproductionsinfos" component={DakonProductionsInfos} />
          <Route path="/dakonproductinfos" component={DakonProductInfos} />
          <Route path="/dakonproductions" component={DakonProductions} />
          <Route path="/disclaimer" component={Disclaimer} />
          <Route path="/businesstrainings" component={BusinessTrainings} />
          <Route path="/softwaredevtrainings" component={SoftwareDevTrainings} />
          <Route path="/trainings" component={Trainings} />
          <Route path="/logout" component={Logout} />
        </HashRouter>
      </div>
    );
  }
}

export default withTranslation()(Main);
