import React, { Component } from "react";
import DakonProductions from "./dakonproduction/DakonProductions";
import { withTranslation } from "react-i18next";

class Home extends Component {
  render() {
    const { t } = this.props;
    return (
      <div className="content-akwaba" >
         <DakonProductions />      
      </div>
    );
  }
}

export default withTranslation() (Home);
