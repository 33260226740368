import React, { Component } from 'react';

class BookingDataTable extends Component {
    render() {
        return (                  
            <tr>                     
                <td>
                    {this.props.obj.id}
                </td>
                <td>
                    {this.props.obj.firstName}
                </td>
                <td>
                    {this.props.obj.lastName}
                </td>
                <td>
                    {this.props.obj.email}
                </td>
                <td>
                    {this.props.obj.phoneNumber}
                </td>
                <td>
                    {this.props.obj.maladie}
                </td>
                <td>
                    {this.props.obj.product}
                </td>
                <td>
                    {this.props.obj.quantity}
                </td>
                <td>
                    {this.props.obj.complete}
                </td>
                <td>
                    {this.props.obj.created_at}
                </td>
                <td>
                    {this.props.obj.updated_at}
                </td>
                <td>
                    {this.props.obj.gender}
                </td>
                <td>
                    {this.props.obj.age}
                </td>
                <td>
                    {this.props.obj.illsince}
                </td>
                <td>
                    {this.props.obj.deliveryinfo}
                </td>
                <td>
                    {this.props.obj.additionalinfo}
                </td>
            </tr>           
        );
    }
}

export default BookingDataTable;