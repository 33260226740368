import { useTranslation } from 'react-i18next';
import { React,useState} from "react";
import axios from "axios";
import ConfigData from "../../../config.json";

const Booking2 = () => {
  const [fname, setFname] = useState('');
  const [lname, setLname] = useState('');
  const [email, setEmail] = useState('');
  const [phonenumber, setPhonenumber] = useState('');
  const [age, setAge] = useState(''); 
  const [quantity, setQuantity] = useState('');
  const [gender, setGender] = useState('');
  const [illsince, setIllsince] = useState('');
  const [deliveryinfo, setDeliveryinfo] = useState('');
  const [messageSent, setMessageSent] = useState('');
  const [clickedButtonButNotPosted, setClickedButtonButNotPosted] = useState('');
  const [clickedNotPostedMessage, setClickedNotPostedMessage] = useState('');
  const [fnameerrormsg, setFirstNameerrormsg] = useState('');
  const [lnameerrormsg, setLastNameerrormsg] = useState('');
  const [ageerrormsg, setAgeerrormsg] = useState('');
  const [emailerrormsg, setEmailerrormsg] = useState('');
  const [phonenumbererrormsg, setPhoneNumbererrormsg] = useState('');
  const [gendererrormsg, setGendererrormsg] = useState('');
  const [quantityerrormsg, setQuantityerrormsg] = useState('');
  const [illsinceerrormsg, setIllsinceerrormsg] = useState('');
  
   
  const { t } = useTranslation();

  const getApiPath = () => { 
    if(ConfigData.ENVIRONMENT.DEV)
           return ConfigData.BASE_URL_LOCAL+ConfigData.PAGES_URL.BOOKINGS;  
    else if(ConfigData.ENVIRONMENT.LIVE)
          return ConfigData.BASE_URL_LIVE+ConfigData.PAGES_URL.BOOKINGS;
    else if(ConfigData.ENVIRONMENT.MANUAL)
         return ConfigData.MANUAL_URL.BOOKINGS;
     else return "no environment was specified";
  };

  const handleSubmit = event => {
    console.log('handleSubmit ran');
    event.preventDefault(); // 👈️ prevent page refresh
   const body ={fname,lname,email,phonenumber,age,quantity,gender,illsince};

   setClickedButtonButNotPosted(<p>{t("pages.booking.msginvalidform")} </p>);
     if (validate()) {            
      const API_PATH = getApiPath();
      axios({
        method: "post",
        url: `${API_PATH}`,
        data: body,
      })
        .then((result) => {
          if (result.status === 200) {         
            setFname('');
            setLname('');          
            setEmail('');
            setPhonenumber('');           
            setGender('');
            setQuantity('');
            setIllsince('');
            setAge('');
            setDeliveryinfo('');
            setEmailerrormsg('');
            setFirstNameerrormsg('');
            setLastNameerrormsg('');          
            setEmailerrormsg('');
            setPhoneNumbererrormsg('');           
            setGendererrormsg('');
            setQuantityerrormsg('');
            setIllsinceerrormsg('');
            setAgeerrormsg('');
            setMessageSent(true);
            setClickedButtonButNotPosted(false);
            setClickedNotPostedMessage('');            
          }
        })
        .catch(function (error) {    
          console.log(error);
        });     
    } else { 
      setClickedButtonButNotPosted(true);
      setClickedNotPostedMessage(<p>{t("pages.booking.msginvalidform")} </p>);  
      console.log("Data was not sent");
      return;
    }
  }

 const  validate = ()=> {
    let isValid = true;
       

    if (!gender) {
      isValid = false;
      setGendererrormsg(<p>{t("pages.booking.genderinvalidmsg")}</p>);
    }
    
    if (!fname) {
      isValid = false;
      setFirstNameerrormsg(<p>{t("pages.booking.firstnameinvalidmsg")}</p>);
    }

    if (!lname) {
      isValid = false;
      setLastNameerrormsg(<p>{t("pages.booking.lastnameinvalidmsg")}</p>);
    }

    if (!age) {
      isValid = false;
      setAgeerrormsg(<p>{t("pages.booking.ageinvalidmsg")}</p>);
    }


    if (!phonenumber) {
      isValid = false;
      setPhoneNumbererrormsg(<p>{t("pages.booking.phoneinvalidmsg")}</p>);
     }
    if (!email) {
      isValid = false;
      setEmailerrormsg(<p>{t("pages.booking.emailinvalidmsg")}</p>);
    }

    if (typeof email !== "undefined") {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(email)) {
        isValid = false;
        setEmailerrormsg(<p>{t("pages.booking.emailpatterninvalidmsg")}</p>);
      }
    }    
    
    if (!quantity) {
      isValid = false;
      setQuantityerrormsg(<p>{t("pages.booking.quantityinvalidmsg")}</p>);
    }
    if (!illsince) {
      isValid = false;
      setIllsinceerrormsg(<p>{t("pages.booking.illsinceinvalidmsg")}</p>);
    }

    return isValid;
  };
  
  return (
    <div  className="content-akwaba">
      <form action="#">      
           <div><p>{t("pages.booking.header1")}</p></div>
            <div className="form-group">
              <label>{t("pages.booking.gender")} </label>
              <input
                type="text"
                name="gender"
                value={gender}
                onChange={event => setGender(event.target.value)}
                placeholder={t("pages.booking.genderph")}
                id="gender"
              />
              <div className="text-danger">{gendererrormsg}</div>
            </div>

            <div className="form-group">
              <label>{t("pages.booking.firstname")} </label>
              <input
                type="text"
                name="fname"
                value={fname}
                onChange={event => setFname(event.target.value)}
                placeholder={t("pages.booking.firstnameph")}
                id="fname"
              />
              <div className="text-danger">{fnameerrormsg}</div>
            </div>

            <div className="form-group">
              <label>{t("pages.booking.lastname")}</label>
              <input
                type="text"
                name="lname"
                value={lname}
                onChange={event => setLname(event.target.value)}
                placeholder={t("pages.booking.lastnameph")}
                id="lname"
              />
              <div className="text-danger">{lnameerrormsg}</div>
            </div>

            <div className="form-group">
              <label>{t("pages.booking.email")}</label>
              <input
                type="text"
                name="email"
                value={email}
                onChange={event => setEmail(event.target.value)}
                placeholder={t("pages.booking.emailph")}
                id="email"
              />
              <div className="text-danger">{emailerrormsg}</div>
            </div>

            <div className="form-group">
              <label>{t("pages.booking.phone")}</label>
              <input
                type="text"
                name="phonenumber"
                value={phonenumber}
                onChange={event => setPhonenumber(event.target.value)}
                placeholder={t("pages.booking.phoneph")}
                id="phonenumber"
              />
              <div className="text-danger">{phonenumbererrormsg}</div>
            </div>

           <div className="form-group">
              <label>{t("pages.booking.age")}</label>
              <input
                type="text"
                name="age"
                value={age}
                onChange={event => setAge(event.target.value)}
                placeholder={t("pages.booking.ageph")}
                id="age"
              />
              <div className="text-danger">{ageerrormsg}</div>
            </div>

            <div className="form-group">
              <label>{t("pages.booking.illsince")}</label>
              <input
                type="text"
                name="illsince"
                value={illsince}
                onChange={event => setIllsince(event.target.value)}
                placeholder={t("pages.booking.illsinceph")}
                id="illsince"
              />
              <div className="text-danger">{illsinceerrormsg}</div>
            </div>
           

            <div className="form-group">
              <label>{t("pages.booking.quantity")}</label>
              <input
                type="text"
                name="quantity"
                value={quantity}
                onChange={event => setQuantity(event.target.value)}
                placeholder={t("pages.booking.quantityph")}
                id="quantity"
              />
              <div className="text-danger">{quantityerrormsg}</div>
            </div>


            <div className="form-group">
              <label>{t("pages.booking.deliveryinfo")}</label>
              <input
                type="text"
                name="deliveryinfo"
                value={deliveryinfo}
                onChange={event => setDeliveryinfo(event.target.value)}
                placeholder={t("pages.booking.deliveryinfoph")}
                id="deliveryinfo"
              />              
            </div>
           
            <input
              className="btn btn-primary"
              type="submit"
              onClick={(e) =>  {handleSubmit(e)}}
              value={t("pages.booking.submit")}
            />
            <div>
              {messageSent && (
                <div>
                  {t("pages.booking.thankyou1")} <br />
                  {t("pages.booking.thankyou2")}
                  <br />
                  {t("pages.booking.thankyou3")}{" "}
                </div>
              )}
                  {clickedButtonButNotPosted && (
                <div>
                  {clickedNotPostedMessage} <br />                  
                </div>
                 )}
            </div>

      </form>
    </div>
  );
};

export default Booking2;

