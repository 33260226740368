import React, { Component } from "react";
import { NavLink, HashRouter } from "react-router-dom";
import { bounce } from "react-animations";
import styled, { keyframes } from "styled-components";
import { withTranslation } from "react-i18next";

const Bounce = styled.div`
  animation: 2s ${keyframes`${bounce}`} infinite;
`;

class DakonSolutions extends Component {
  render() {
    const { t } = this.props;
    return (
      <div className="">
        <div className="content-justscript-akwaba">
          <div className="comingsoon">
            <Bounce>
            <p>{t("pages.tiles.trade.text.bounce")}<br/>         
           </p>
            </Bounce>
          </div>
          <div>
            <p>{t("pages.tiles.trade.text.content")}<br/></p>
           <p>{t("pages.tiles.trade.text.h1-1")}</p>
          <HashRouter> 
              <NavLink to="/booking"><span className ="akwaba-tiles-navlinks-inbody">{t("navbar.placeanorder")}</span></NavLink>
          </HashRouter>   
       
                     
            <p>{t("pages.tiles.trade.text.h1-2")}</p>  <p>{t("pages.tiles.trade.text.numbers")}<br/></p>       
            <p>{t("pages.tiles.trade.text.h1-3")}</p> <p>{t("pages.tiles.trade.text.emails")}</p>            
                
         </div>
         </div>
      </div>
    );
  }
}

export default withTranslation()(DakonSolutions);
