import React from 'react';
import styled from 'styled-components';
import {NavLink, HashRouter } from "react-router-dom";

const Nav = styled.nav`
  width: 100%;
  height: 100px;
  background-colour:#f1f1f1
  border-bottom: 2px solid #f1f1f1;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  .logo {
    padding: 15px 0;
  }
`

const AdminPage = () => {
  return (
    <Nav>
       <HashRouter>    
      <div className="">
      <NavLink to="/bookingadmin"><strong>Booking</strong></NavLink>
      </div>
      <div className="">
      <NavLink to="/messageadmin"><strong>Message</strong></NavLink>
      </div>
      </HashRouter> 
    </Nav>
  )
}


//export default withTranslation()(Navbar);
 export default AdminPage
