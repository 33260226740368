
import React, { Component } from "react";
import axios from "axios";
import { withTranslation } from "react-i18next";
import { Redirect } from "react-router";
import ConfigData from "../../config.json";
import { useState, useEffect } from "react";
import DataTable from './BookingDataTable';


class BookingAdminPage extends Component {

  constructor(props) {
    super(props);
    this.state = { usersMessages: [] };
}

componentDidMount() {
    axios.get(this.getApiPath())
        .then(res => {
            this.setState({ usersMessages: res.data });  
            console.log("response: ", res.data)         
        })
        .catch(function (error) {
            console.log(error);
        })
}

dataTable() {
    return this.state.usersMessages.map((data, i) => {
      console.log("data: ", data, "index: ", i)
        return <DataTable obj={data} key={i} />;
    });
}

getApiPath = () => { 
  if(ConfigData.ENVIRONMENT.DEV)
         return ConfigData.BASE_URL_LOCAL+ConfigData.PAGES_URL.RETRIEVEBOOKINGS;  
  else if(ConfigData.ENVIRONMENT.LIVE)
        return ConfigData.BASE_URL_LIVE+ConfigData.PAGES_URL.RETRIEVEBOOKINGS;
  else if(ConfigData.ENVIRONMENT.MANUAL)
       return ConfigData.MANUAL_URL.RETRIEVEBOOKINGS;
   else return "no environment was specified";
};
  
  render() {
    const { t } = this.props;
 
    return (      
      <div>
      <table className="table table-striped adminpage">
                        <thead className="adminpageheader">
                            <tr>
                                <td><strong>Id</strong></td>
                                <td><strong>First Name</strong></td>
                                <td><strong>Last Name</strong></td>
                                <td><strong>Email</strong></td>
                                <td><strong>Phone Number</strong></td>  
                                <td><strong>Illness</strong></td>
                                <td><strong>Product</strong></td>
                                <td><strong>Quantity</strong></td>
                                <td><strong>Complete</strong></td>
                                <td><strong>Date created</strong></td>
                                <td><strong>Date updated</strong></td>
                                <td><strong>Gender</strong></td>
                                <td><strong>Age</strong></td>
                                <td><strong>Illness start</strong></td>
                                <td><strong>Delivery Info</strong></td>
                                <td><strong>Additional info</strong></td>                                   
                            </tr>
                        </thead>
                        <tbody>
                            {this.dataTable()}
                        </tbody>
                    </table>       
      </div>
    );
  }
}
 
export default withTranslation()(BookingAdminPage);