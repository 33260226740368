import React, { Component } from "react";
//import akwabagroup1 from "../../img/akwabagroup1.PNG";
import {NavLink, HashRouter } from "react-router-dom";
import Accordion from 'react-bootstrap/Accordion';
import Card from "react-bootstrap/Card";
import { withTranslation  } from "react-i18next";

class DakonProductInfos extends Component {
  render() {
    const { t } = this.props;
    return (
      <div className="">    
      <div className="content-justscript-akwaba">   
<Accordion defaultActiveKey="0">  
  <Card>
    <Accordion.Toggle as={Card.Header} eventKey="0">
       <strong> The disease </strong> 
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="0">
      <Card.Body className="content-accordion" >Typhoid is a tropical andemic disease</Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
    <Accordion.Toggle as={Card.Header} eventKey="1">
    <strong> The product </strong>      
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="1">
      <Card.Body className="content-accordion">Nokad is a plant base detection</Card.Body>
    </Accordion.Collapse>
  </Card> 
  <Card>
    <Accordion.Toggle as={Card.Header} eventKey="2">
    <strong> Posology </strong>      
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="2">
      <Card.Body className="content-accordion">no ore that 1 litre a day</Card.Body>
    </Accordion.Collapse>
  </Card>

  <Card>
    <Accordion.Toggle as={Card.Header} eventKey="2">
    <strong>  Side effect</strong>       
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="2">
      <Card.Body className="content-accordion">Tiredness</Card.Body>
    </Accordion.Collapse>
  </Card>  
</Accordion>
</div>  

    </div>
    );
  }
}


export default withTranslation ()(DakonProductInfos);
