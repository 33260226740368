import React, { Component } from "react";
//import akwabagroup1 from "../../img/akwabagroup1.PNG";
import {NavLink, HashRouter } from "react-router-dom";
import Accordion from 'react-bootstrap/Accordion';
import Card from "react-bootstrap/Card";
import { withTranslation  } from "react-i18next";

class Disclaimer extends Component {
  render() {
    const { t } = this.props;
    return (
      <div className="content-justscript-akwaba">   
            <p>{t("disclaimer.content.c1")}</p>
            <p>{t("disclaimer.content.c2")}</p>
     </div> 
    );
  }
}

export default withTranslation ()(Disclaimer);
